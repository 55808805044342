exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-booking-tsx": () => import("./../../../src/pages/Booking.tsx" /* webpackChunkName: "component---src-pages-booking-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/Contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-main-page-tsx": () => import("./../../../src/pages/MainPage.tsx" /* webpackChunkName: "component---src-pages-main-page-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/TermsOfService.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */)
}

